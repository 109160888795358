import React, {useState} from 'react';
import DashboardHeader from './DashboardHeader';
import DashboardSummary from './DashboardSummary';
import RewardHistory from './RewardHistory';
import OperationHistory from './OperationHistory';
import NotConnected from '../components/NotConnected';
import { useAccount } from '../../hooks/useWallet';

function Dashboard() {
  const {account} = useAccount();
  const [operationUpdateTrigger, setOperationUpdateTrigger] = useState(0);

  const triggerOperationUpdate = () => {
    setOperationUpdateTrigger(prev => prev + 1);
  };
  return (
    <div>
      <DashboardHeader />
      {!account &&
        <div className="container mx-auto mt-6 h-screen">
          <NotConnected />
        </div>
      }

      {account && <div className="container mx-auto py-4 max-w-[1280px]">
        <DashboardSummary triggerOperationUpdate={triggerOperationUpdate} />
        <RewardHistory />
        <OperationHistory updateTrigger={operationUpdateTrigger} />
      </div>}
    </div>
  );
}

export default Dashboard;