import React, { useEffect, useState } from 'react';

import { getOperationList } from '../../api';
import { useAccount, useChainId } from '../../hooks/useWallet';
import { getFormatTime, getOperationType, cutString } from '../../utils';
import { ReactComponent as ExternalIcon } from '../../assets/icons/external.svg';

import NoData from '../components/NoData';
import PaginationComponent from '../components/Pagination/Pagination';
import CopyButton from '../components/copy';
import useCurrentNetwork from '../../hooks/useCurrentNetwork';
import poolConfig from '../../../pool.config';

function OperationHistory({ updateTrigger }) {
  const chainId = useChainId();
  const { account, isAccountLoading } = useAccount();
  const { isTestNetEnv, isLoading } = useCurrentNetwork();
  const [operations, setOperations] = useState([]);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);

  useEffect(() => {
    if (!isLoading && !isAccountLoading) {
      getUserOperationList(current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, chainId, current, isLoading, isAccountLoading]);

  useEffect(() => {
    getUserOperationList(current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTrigger]);

  const getUserOperationList = async (page) => {
    if (!account) {
      setTotal(0);
      setOperations([]);
      return;
    };

    const params = {
      network_type: isTestNetEnv ? 'testnet' : 'mainnet',
      address: account,
      page_size: 10,
      offset: (page - 1) * 10
    }

    if ((params.network_type === 'testnet' && !params.address.includes('cfxtest:') ) || (params.network_type === 'mainnet' && params.address.includes('cfxtest:'))) {
      return
    }

    const { data } = await getOperationList(params);

    setTotal(data.total_count);
    setOperations(data.items)
  }

  const handlePageChange = (page) => {
    setCurrent(page);
  }

  const goExternal = (tx_hash) => {
    const explorerUrl = isTestNetEnv ? poolConfig.testnet.txUrl : poolConfig.mainnet.txUrl;
    window.open(`${explorerUrl}${tx_hash}`, '_blank');
  }

  return (
    <div className="mt-16 mb-6">
      <h2 className="mb-4 text-lg font-semibold">Operation History</h2>
      {
        operations.length === 0 ? (
          <NoData />
        ) : (
          <>
            <div className="w-full p-8 bg-base-200 rounded-2xl">
              <div className="overflow-x-auto">
                <div className="min-w-full inline-block align-middle">
                  <table className="w-full border-collapse border-spacing-0">
                    <thead className="bg-[#EFEFEF] rounded-md">
                      <tr>
                        <th className="px-4 py-2 text-left text-gray-text whitespace-nowrap">Time (UTC+0)</th>
                        <th className="px-4 py-2 text-left text-gray-text whitespace-nowrap">Amount</th>
                        <th className="px-4 py-2 text-left text-gray-text whitespace-nowrap">Operation</th>
                        <th className="px-4 py-2 text-right text-gray-text whitespace-nowrap">TxHash</th>
                      </tr>
                    </thead>
                    <tbody>
                      {operations.map((operation, index) => (
                        <tr key={index}>
                          <td className="px-4 py-2 font-medium text-base-100 whitespace-nowrap">{getFormatTime(operation.timestamp)}</td>
                          <td className="px-4 py-2 font-medium text-base-100 whitespace-nowrap">
                            {operation.amount}
                            <span className="text-accent"> CFX</span>
                          </td>
                          <td className="px-4 py-2 font-medium text-base-100 whitespace-nowrap">{getOperationType(operation.type)}</td>
                          <td className="px-4 py-2 font-medium text-base-100 whitespace-nowrap">
                            <div className="flex items-center justify-end">
                              {cutString(operation.tx_hash, 4, 6)}
                              <CopyButton data={operation.tx_hash} />
                              <ExternalIcon className="w-4 h-4 text-black ml-1 cursor-pointer" onClick={() => goExternal(operation.tx_hash)} />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <div className="flex justify-end mt-4">
                    <PaginationComponent
                      pageSize={10}
                      total={total}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }
    </div>
  );
}

export default OperationHistory;