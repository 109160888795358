import { useMemo, useState, useEffect, useCallback } from 'react';
import { stakeAbi } from '../constants/contractAbi';
import { utils } from 'ethers';
import { Conflux } from "js-conflux-sdk/dist/js-conflux-sdk.umd.min.js";
import { NETWORK_ID_CORE_MAINNET, NETWORK_ID_CORE_TESTNET } from "../constants";
import useCurrentSpace from './useCurrentSpace';
import useCurrentNetwork from '../hooks/useCurrentNetwork';
import poolConfig from '../../pool.config';

const usePoolContract = () => {
    const { isTestNetEnv } = useCurrentNetwork();
    const currentSpace = useCurrentSpace();
    const [network, setNetwork] = useState(null);
    const [isContractLoading, setIsContractLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchChainId = useCallback(async () => {
        setIsContractLoading(true);
        setError(null);

        if (!window.conflux) {
            setError('Conflux object not found. Please ensure you have the Conflux wallet extension installed.');
            setIsContractLoading(false);
            return;
        }

        try {
            const chainIdHex = await window.conflux.request({ method: 'cfx_chainId' });
            const newChainId = parseInt(chainIdHex, 16);

            const isTestNet = newChainId === NETWORK_ID_CORE_TESTNET;
            const envType = isTestNet ? 'testnet' : 'mainnet';
            
            const newNetwork = {
                url: poolConfig[envType][currentSpace].RPC,
                networkId: isTestNet ? NETWORK_ID_CORE_TESTNET : NETWORK_ID_CORE_MAINNET,
                name: poolConfig[envType][currentSpace].name
            };
            
            setNetwork(newNetwork);
        } catch (error) {
            console.error('Error fetching chainId:', error);
            setError('Failed to fetch chain ID. Please check your network connection and try again.');
        } finally {
            setIsContractLoading(false);
        }
    }, [currentSpace]);

    useEffect(() => {
        fetchChainId();
    }, [fetchChainId]);

    useEffect(() => {
        const handleChainChanged = () => {
           fetchChainId();
        };
    
        if (window.conflux) {
            window.conflux.on('chainChanged', handleChainChanged);
        }
    
        return () => {
            if (window.conflux) {
                window.conflux.removeListener('chainChanged', handleChainChanged);
            }
        };
    }, []);

    const poolAddress = isTestNetEnv ? poolConfig.testnet.contractAddress : poolConfig.mainnet.contractAddress;

    const contractData = useMemo(() => {
        if (!network) {
            return {
                contract: null,
                interface: new utils.Interface(stakeAbi)
            };
        }

        const controller = new Conflux(network);

        return {
            contract: controller.Contract({
                abi: stakeAbi,
                address: poolAddress,
            }),
            interface: new utils.Interface(stakeAbi)
        };
    }, [network, poolAddress]);

    return {
        ...contractData,
        isContractLoading,
        error
    };
};

export default usePoolContract;