import { useEffect, useState } from 'react';
import { NETWORK_ID_CORE_MAINNET, NETWORK_ID_CORE_TESTNET } from "../constants";
import poolConfig from '../../pool.config';
import useCurrentSpace from './useCurrentSpace';

const useCurrentNetwork = () => {
  const currentSpace = useCurrentSpace();
  const [chainId, setChainId] = useState(null);
  const [isTestNetEnv, setIsTestNetEnv] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [network, setNetwork] = useState(() => {
    if (currentSpace) {
      const envType = 'mainnet'; // 默认使用主网
      return {
        url: poolConfig[envType][currentSpace].RPC,
        networkId: NETWORK_ID_CORE_MAINNET,
        name: poolConfig[envType][currentSpace].name
      };
    }
    return null;
  });

  useEffect(() => {
    const updateNetwork = (isTestNet) => {
      if (!currentSpace) {
        return null;
      }
    
      const networkId = isTestNet ? NETWORK_ID_CORE_TESTNET : NETWORK_ID_CORE_MAINNET;
      const envType = isTestNet ? 'testnet' : 'mainnet';
      const url = poolConfig[envType][currentSpace].RPC;
      
      return {
        url,
        networkId,
        name: poolConfig[envType][currentSpace].name
      };
    };

    const fetchChainId = async () => {
      if (window.conflux) {
        try {
          const chainIdHex = await window.conflux.request({ method: 'cfx_chainId' });
          const newChainId = parseInt(chainIdHex, 16);
          setChainId(newChainId);
          setIsTestNetEnv(newChainId === NETWORK_ID_CORE_TESTNET);
          
          const newNetwork = updateNetwork(newChainId === NETWORK_ID_CORE_TESTNET);
          setNetwork(newNetwork);
        } catch (error) {
          console.error('Error fetching chainId:', error);
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    fetchChainId();

    // 添加事件监听器以处理链ID变化
    const handleChainChanged = (newChainIdHex) => {
      const newChainId = parseInt(newChainIdHex, 16);
      setChainId(newChainId);
      setIsTestNetEnv(newChainId === NETWORK_ID_CORE_TESTNET);
    };

    if (window.conflux) {
      window.conflux.on('chainChanged', handleChainChanged);
    }

    // 清理函数
    return () => {
      if (window.conflux) {
        window.conflux.removeListener('chainChanged', handleChainChanged);
      }
    };
  }, []); 

  return { network, isTestNetEnv, isLoading };
}

export default useCurrentNetwork;
