import { NETWORK_ID_CORE_MAINNET, NETWORK_ID_CORE_TESTNET } from "../constants";
import { useEffect, useState, useCallback } from "react";
import useCurrentSpace from "./useCurrentSpace";

const useIsNetworkMatch = () => {
  const allowedNetworkIds = [
    NETWORK_ID_CORE_MAINNET,
    NETWORK_ID_CORE_TESTNET,
  ];

  const currentSpace = useCurrentSpace();
  const [usedChainId, setUsedChainId] = useState(null);

  const checkIsNetwrokMatch = useCallback(async () => {
    if (!window.conflux) {
      return
    }

    try {
      const newChainIdHex = await window.conflux.request({ method: 'cfx_chainId' });

      const newChainId = parseInt(newChainIdHex, 16);

      setUsedChainId(newChainId);
    } catch (error) {
      console.error('Error fetching chainId:', error);
      setUsedChainId(null);
    }
  }, [currentSpace]);

  useEffect(() => {
    checkIsNetwrokMatch()
  }, [checkIsNetwrokMatch]);

  useEffect(() => {
    const handleChainChanged = () => {
      checkIsNetwrokMatch()
    };

    if (window.conflux) {
      window.conflux.on('chainChanged', handleChainChanged);
    }

    return () => {
      if (window.conflux) {
        window.conflux.removeListener('chainChanged', handleChainChanged);
      }
    };
  }, []);

  if (!currentSpace) return true;

  return usedChainId !== null && allowedNetworkIds.includes(usedChainId);
};

export default useIsNetworkMatch;