import React, { useState, useEffect } from "react";
import { message, Spin, Tooltip } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { format } from 'js-conflux-sdk';
import { calculateGasMargin } from "../../utils";
import { InfoCircleOutlined } from '@ant-design/icons';
import { useAccount, useSendTransaction, Unit, useChainId, useBalance } from '../../hooks/useWallet';
import useConflux from "../../hooks/useConflux";
import usePoolContract from '../../hooks/usePoolContract'
import useCurrentNetwork from '../../hooks/useCurrentNetwork'
import BigNumber from 'bignumber.js'
import cfxLogo from '../../images/cfx.svg'

import { CFX_BASE_PER_VOTE } from '../../constants'
import { getChainInfo } from '../../api'

import InputComponent from "../components/Input";
import poolConfig from "../../../pool.config";
import TxModal from './TxModal'
import Tabs from "./Tabs";
import Unstake from "./Unstake";

function Home() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryParamsType = queryParams.get('type');
  const initialTab = queryParams.get('type') || 'stake';

  const [loading, setLoading] = useState(false)
  const [inputStakeCfx, setInputStakeCfx] = useState('')
  const { contract: posPoolContract } = usePoolContract();
  const [stakeModalShown, setStakeModalShown] = useState(false)
  const [txModalShown, setTxModalShown] = useState(false)
  const [stakeLoading, setStakeLoading] = useState(false)
  const [txHash, setTxHash] = useState('')
  const [tab, setTab] = useState(initialTab);
  const [apr, setApr] = useState(0);
  const [rewardFee, setRewardFee] = useState(0);
  const [txStatus, setTxStatus] = useState('pending');
  // use hook to initialize conflux
  const { conflux: confluxController, checkTransactionStatus } = useConflux();
  const { isTestNetEnv } = useCurrentNetwork();

  const chainId = useChainId();
  const sendTransaction = useSendTransaction()
  const navigate = useNavigate()

  const {account: accountAddress} = useAccount()

  // get balance through hooks and then process it to readable format
  const balance = useBalance()
  const [displayBalance, setDisplayBalance] = useState(0)

  useEffect(() => {
    setInputStakeCfx('')
  }, [queryParamsType])

  useEffect(() => {
    getCurrentChainInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId])

  useEffect(() => {
    setStakeLoading(true);
    const timer = setTimeout(() => {
      setDisplayBalance(balance || 0);
      setStakeLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [balance, accountAddress, chainId]);

  const handleContinue = async () => {
    submit('stake');
  }
  
  const getCurrentChainInfo = async () => {
    const params = {
      chain: 'conflux_cspace'
    }
    const res = await getChainInfo(params)

    const currentApr = res.data.find(item => item.chain_id === Number(chainId))?.apr || 0
    const currentRewardFee = res.data.find(item => item.chain_id === Number(chainId))?.fee || 0
    
    setApr(currentApr)
    setRewardFee(currentRewardFee)
  }

  const handleButtonDisabled = () => {
    return !inputStakeCfx || Number(inputStakeCfx) < 1000 || !accountAddress || Number(displayBalance) < Number(inputStakeCfx) || Number(inputStakeCfx) % 1000 !== 0 || loading
  }

  const handleActivate = async () => {
    if (typeof window.conflux === 'undefined') {
      console.error('Fluent Wallet has not been install yet!')
      window.open('https://fluentwallet.com/', '_blank')
      return
    }

    await window.conflux.request({ method: 'cfx_requestAccounts' })
  }

  const submit = async type => {
    if (!accountAddress) {
      message.error('Please connect Fluent')
      return
    }
    setLoading(true)

    try {
      let data = ''
      let estimateData = {}
      let value = 0
      switch (type) {
        case 'stake':
          value = new BigNumber(inputStakeCfx)
            .multipliedBy(10 ** 18)
            .toString(10)
          const stakeVote = new BigNumber(inputStakeCfx)
            .dividedBy(CFX_BASE_PER_VOTE)
            .toString(10)

          data = posPoolContract.increaseStake(stakeVote).data

          estimateData = await posPoolContract
            .increaseStake(stakeVote)
            .estimateGasAndCollateral({
              from: accountAddress,
              value,
            })

          break
        default:
          break
      }

      const txParams = {
        to: format.address(isTestNetEnv ? poolConfig.testnet.contractAddress : poolConfig.mainnet.contractAddress, Number(chainId)),
        data,
        value: Unit.fromMinUnit(value).toHexMinUnit()
      }

      if (estimateData?.gasLimit) {
        txParams.gas = Unit.fromMinUnit(calculateGasMargin(estimateData?.gasLimit || 0)).toHexMinUnit();

        const gasPrice = await confluxController.provider.call("cfx_gasPrice");
        if (gasPrice) txParams.gasPrice = gasPrice
      }

      if (estimateData?.storageCollateralized) {
        txParams.storageLimit = Unit.fromMinUnit(calculateGasMargin(String(estimateData?.storageCollateralized || 0))).toHexMinUnit()
      }

      if (stakeModalShown) {
        setStakeModalShown(false)
      }

      const txHash = await sendTransaction(txParams)

      setTxHash(txHash)
      setTxModalShown(true)

      setTxStatus('pending');
      await checkTransactionStatus(txHash, setTxStatus);

    } catch (error) {
      console.error('error', error)
      if (error.message.includes('NotEnoughCash')) {
        message.warning('Your balance is not enough')
      }
      else {
        message.warning('Transaction Rejected')
      }
    } finally {
      setLoading(false)
    }
  }

  const onStakeChange = data => {
    setInputStakeCfx(data)
  }

  const goFirstPage = () => {
    navigate('/');
  }

  return (
    <div className="container flex flex-wrap max-w-3xl px-0 py-8 lg:py-20 mx-auto lg:px-5">
      <div className="w-full">
        <h1 className="flex items-center font-bold cursor-pointer" onClick={goFirstPage}>
          <img src={cfxLogo} alt="Conflux Logo" className="h-12 mr-4" />
          <div className="text-lg lg:text-2xl">Conflux Staking - Core Space</div>
        </h1>

        <Tabs currentTab={tab} onChangeTab={setTab} />

        {tab === 'stake' && (
          <>
            <div className="object-cover object-center w-full mt-0 rounded-lg md:mt-6">
              <div className="p-4 rounded-lg bg-base-200 md:p-8">
                <div className="items-start block sm:flex">
                  <div className="w-full mt-2 md:mt-0">
                    <div className="relative flex items-center">
                      <InputComponent
                        onUpdate={(newValue) => onStakeChange(newValue)}
                        size="medium"
                        title=""
                        type="number"
                        pattern="[0-9]*"
                        min="0"
                        step="1000"
                        className="w-full text-[#000000]"
                        placeholder="Enter a multiple of 1000"
                      />
                      {inputStakeCfx && (
                        <span className="absolute top-[53px] right-4 md:inset-y-0 md:right-10 md:flex items-center text-red-500 z-50">
                          {Number(inputStakeCfx) < 1000 && 'Should be greater than 1000'}
                          {Number(inputStakeCfx) >= 1000 && Number(displayBalance) < Number(inputStakeCfx) && 'Insufficient Balance'}
                          {Number(inputStakeCfx) >= 1000 && Number(displayBalance) >= Number(inputStakeCfx) && Number(inputStakeCfx) % 1000 !== 0 && 'Should be in multiples of 1000'}
                        </span>
                      )}
                    </div>
                    <div className="relative">
                      <p className="absolute flex items-center mt-2 text-base">
                        <span className="mr-2 text-gray-text">Balance:</span>
                        {stakeLoading ? (
                          <span className="ml-1 w-24 h-4 bg-[#E2E8F0] rounded animate-pulse"></span>
                        ) : (
                          <span className="font-semibold">{displayBalance ? displayBalance : 0} CFX</span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>

                <section className="mt-20 body-font">
                  <div className="mx-auto">
                    <dl className="grid mb-0 gap-x-4 gap-y-8 lg:grid-cols-3 sm:grid-cols-2">
                      <div className="col-span-1">
                        <dt className="text-sm font-medium text-gray-text">APR</dt>
                        <dd className="mt-2 text-base font-medium tracking-tighter lg:text-lg">{apr} %</dd>
                      </div>
                      <div className="col-span-1">
                        <dt className="text-sm font-medium text-gray-text">Rewards fee</dt>
                        <dd className="mt-2 text-base font-medium tracking-tighter lg:text-lg">{rewardFee*100} %</dd>
                      </div>
                    </dl>
                  </div>
                </section>
              </div>

              <div className="p-4 my-8 text-sm rounded-lg bg-base-200 md:p-8">
                <div className="flex items-center justify-between">
                  <div className="flex items-center mt-2 text-sm font-medium lg:text-base text-gray-text">
                    <div className="mr-2">Lock period</div>
                    <Tooltip title="Users are required to wait 14 days after staking before they can unstake." color="white" key="white" overlayInnerStyle={{ color: '#333' }}><InfoCircleOutlined /></Tooltip>
                  </div>
                  <div className="mt-2 text-sm font-medium lg:text-base">14 days</div>
                </div>

                <div className="flex items-center justify-between mt-4">
                  <div className="mt-2 text-sm font-medium lg:text-base text-gray-text">Reward distribution</div>
                  <div className="mt-2 text-sm font-medium lg:text-base">Daily</div>
                </div>
              </div>

              <div className="flex justify-end w-full">
                {accountAddress ? (
                  <button
                    disabled={handleButtonDisabled()}
                    className="flex items-center justify-center rounded-md border-none w-28 h-12 font-semibold bg-base-100 text-white disabled:bg-[#dbdcde]"
                    onClick={handleContinue}
                  >
                    {loading && <Spin className="!mr-2" />}
                    <span>SUBMIT</span>
                  </button>
                ) : (
                  <button
                    className="flex items-center justify-center h-12 font-semibold text-white border-none rounded-md w-28 bg-base-100"
                    onClick={handleActivate}
                  >
                    CONNECT
                  </button>
                )}
              </div>
            </div>
          </>
        )}

        {tab === 'unstake' && (
          <Unstake
            balance={displayBalance}
            accountAddress={accountAddress}
          />
        )}
      </div>

      <TxModal
        visible={txModalShown}
        setVisible={setTxModalShown}
        txHash={txHash}
        txStatus={txStatus}
      ></TxModal>
    </div>
  );
}

export default Home;