// hooks/useConflux.js
import { useEffect, useState, useCallback } from 'react';
import { format, Drip, Conflux } from 'js-conflux-sdk/dist/js-conflux-sdk.umd.min.js';
import useCurrentNetwork from './useCurrentNetwork';

const useConflux = () => {
  const { isTestNetEnv, network } = useCurrentNetwork();
  const [conflux, setConflux] = useState(null);

  const renewConfluxController = useCallback(() => {
    if (network) {
      const cfx = new Conflux({
        url: network.url,
        networkId: network.networkId,
      });
      setConflux(cfx);
    }
  }, [network]);

  useEffect(() => {
    renewConfluxController()
  }, [renewConfluxController])

  useEffect(() => {
    const handleChainChanged = () => {
      renewConfluxController()
    };

    if (window.conflux) {
      window.conflux.on('chainChanged', handleChainChanged);
    }

    return () => {
      if (window.conflux) {
        window.conflux.removeListener('chainChanged', handleChainChanged);
      }
    };
  }, [renewConfluxController]);

  useEffect(() => {
    if (network) {
      const cfx = new Conflux({
        url: network.url,
        networkId: network.networkId,
      });
      setConflux(cfx);
    }
  }, [network, isTestNetEnv]);

  const checkTransactionStatus = async (txHash, setTxStatus) => {
    if (!conflux) return;

    let receipt = null;
    while (receipt === null || receipt.blockHash === null) {
      receipt = await conflux.provider.call('cfx_getTransactionByHash', txHash);
      if (receipt !== null && receipt.blockHash !== null) {

        if (receipt.status === '0x0') {
          setTxStatus('success');
        } else {
          setTxStatus('failed');
        }
        break;
      }
      await new Promise(resolve => setTimeout(resolve, 8000));
    }
  };

  return {
    conflux,
    checkTransactionStatus,
    format,
    Drip,
  };
};

export default useConflux;
