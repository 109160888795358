import React, { useState, useEffect, useCallback } from 'react';
import { Spin, message } from 'antd';
import { calculateGasMargin } from "../../utils";
import { format } from 'js-conflux-sdk';
import { useSendTransaction, Unit, useChainId } from '../../hooks/useWallet';
import { CFX_BASE_PER_VOTE } from '../../constants'
import useConflux from '../../hooks/useConflux';
import InputComponent from "../components/Input";
import usePoolContract from '../../hooks/usePoolContract'
import BigNumber from 'bignumber.js'
import poolConfig from '../../../pool.config';
import TxModal from './TxModal'
import useCurrentNetwork from '../../hooks/useCurrentNetwork';

const Unstake = ({ balance, accountAddress}) => {
  const { contract: posPoolContract, isContractLoading } = usePoolContract()
  const [loading, setLoading] = useState(false);
  const [inputUnstakeCfx, setInputStakeCfx] = useState('')
  const [txHash, setTxHash] = useState('')
  const [txModalShown, setTxModalShown] = useState(false)
  const [unstakeableAmount, setUnstakeableAmount] = useState(0)
  const [unstakeLoading, setUnstakeLoading] = useState(false)
  const [txStatus, setTxStatus] = useState('pending')
  const { conflux: confluxController, checkTransactionStatus } = useConflux()
  const { isTestNetEnv } = useCurrentNetwork();

  const chainId = useChainId()
  const sendTransaction = useSendTransaction()

  const handleUpdate = useCallback(() => {
    if (!isContractLoading) {
      getUnstakeableAmount()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountAddress, chainId, isTestNetEnv, isContractLoading]);

  useEffect(() => {
    handleUpdate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, accountAddress, handleUpdate])

  const onStakeChange = (newValue) => {
    setInputStakeCfx(newValue);
  };

  const handleActivate = async () => {
    if (typeof window.conflux === 'undefined') {
      console.error('Fluent Wallet has not been install yet!')
      window.open('https://fluentwallet.com/', '_blank')
      return
    }

    await window.conflux.request({ method: 'cfx_requestAccounts' })
  }

  const getUnstakeableAmount = async () => {
    if (!posPoolContract) {
      setUnstakeableAmount(0)
      return
    }

    setUnstakeLoading(true)

    try {
      const res = await posPoolContract.userSummary(accountAddress)

      setUnstakeableAmount(res[2][0] * 1000 || 0)
    } catch (error) {
      console.error('Error fetching unstakeable amount:', error)
      setUnstakeableAmount(0)
    } finally {
      setUnstakeLoading(false)
    }
  }

  const handleContinue = async () => {
    submit('unstake')
  };

  const handleButtonDisabled = () => {
    return !inputUnstakeCfx || Number(inputUnstakeCfx) < 1000 || !accountAddress || Number(unstakeableAmount) < Number(inputUnstakeCfx) || Number(inputUnstakeCfx) % 1000 !== 0 || loading
  }

  const submit = async type => {
    if (!accountAddress) {
      message.error('Please connect Fluent')
      return
    }
    setLoading(true)

    try {
      let data = ''
      let estimateData = {}
      let value = 0
      switch (type) {
        case 'unstake':
          value = 0
          const unstakeVote = new BigNumber(inputUnstakeCfx)
            .dividedBy(CFX_BASE_PER_VOTE)
            .toString(10)
          
          data = posPoolContract.decreaseStake(unstakeVote).data
          estimateData = await posPoolContract
            .decreaseStake(unstakeVote)
            .estimateGasAndCollateral({
              from: accountAddress,
            })
         
          break
        default:
          break
      }
      const txParams = {
        to: format.address(isTestNetEnv ? poolConfig.testnet.contractAddress : poolConfig.mainnet.contractAddress, Number(chainId)),
        data,
        value: Unit.fromMinUnit(value).toHexMinUnit()
      }

      if (estimateData?.gasLimit) {
        txParams.gas = Unit.fromMinUnit(calculateGasMargin(estimateData?.gasLimit || 0)).toHexMinUnit();

        const gasPrice = await confluxController.provider.call("cfx_gasPrice");
        if (gasPrice) txParams.gasPrice = gasPrice
      }

      if (estimateData?.storageCollateralized) {
        txParams.storageLimit = Unit.fromMinUnit(calculateGasMargin(String(estimateData?.storageCollateralized || 0))).toHexMinUnit()
      }

      const txHash = await sendTransaction(txParams)
      setTxHash(txHash)
      setTxModalShown(true)

      setTxStatus('pending');
      await checkTransactionStatus(txHash, setTxStatus);
    } catch (error) {
      console.error('error', error)
      message.warning('Transaction Rejected')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="object-cover object-center w-full mt-0 rounded-lg md:mt-6">

      <div className="p-4 my-4 rounded-lg bg-base-200 md:p-8">
        <div className="items-start block sm:flex">
          <div className="w-full mt-2 md:mt-0">
            <div className="relative flex items-center">
              <InputComponent
                onUpdate={(newValue) => onStakeChange(newValue)}
                size="medium"
                title=""
                type="number"
                pattern="[0-9]*"
                min="0"
                step="1000"
                className="w-full z-0 text-[#000000]"
                placeholder="Unstake your CFX"
              />
              {inputUnstakeCfx && (
                <span className="absolute top-[53px] right-4 md:inset-y-0 md:right-10 md:flex items-center text-red-500 z-50">
                {Number(inputUnstakeCfx) < 1000 && 'Should be greater than 1000'}
                {Number(inputUnstakeCfx) >= 1000 && Number(unstakeableAmount) < Number(inputUnstakeCfx) && 'Insufficient Balance'}
                {Number(inputUnstakeCfx) >= 1000 && Number(unstakeableAmount) >= Number(inputUnstakeCfx) && Number(inputUnstakeCfx) % 1000 !== 0 && 'Should be in multiples of 1000'}
              </span>
              )}
            </div>
            
            <p className="flex items-center mt-2 text-base">
              <span className="text-gray-text">Available amount to unstake:</span>

              {unstakeLoading ? (
                <span className="ml-1 w-24 h-4 bg-[#E2E8F0] rounded animate-pulse"></span>
              ) : (
                <span className="ml-1 font-semibold">{unstakeableAmount} CFX</span>
              )}
            </p>
            
          </div>
        </div>

        <section className="mt-8 body-font"></section>
      </div>


      <div className="p-4 my-8 text-sm rounded-lg bg-base-200 md:p-8">
        <p className="text-sm font-medium text-gray-text lg:text-base">Note</p>
        <div className="mt-2 text-sm font-medium lg:text-base">1. Users are required to wait 14 days after staking before they can unstake.</div>
        <div className="mt-2 text-sm font-medium lg:text-base">2. Unstaking amounts should be in multiples of 1000.</div>
      </div>

      <div className="flex justify-end w-full">
        {accountAddress ? (
          <button
            disabled={handleButtonDisabled()}
            className="flex items-center justify-center rounded-md border-none w-28 h-12 font-semibold bg-base-100 text-white disabled:bg-[#dbdcde]"
            onClick={handleContinue}
          >
            {loading && <Spin className="!mr-2" />}
            <span className="">SUBMIT</span>
          </button>
        ) : (
          <button
            className="flex items-center justify-center h-12 font-semibold text-white border-none rounded-md w-28 bg-base-100"
            onClick={handleActivate}
          >
            CONNECT
          </button>
        )}
      </div>
      
      <TxModal
        visible={txModalShown}
        setVisible={setTxModalShown}
        txHash={txHash}
        txStatus={txStatus}
      ></TxModal>
    </div>
  );
};

export default Unstake;