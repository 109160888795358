import BigNumber from "bignumber.js";
import {
  CFX_BASE_PER_VOTE,
  SCAN_URL,
  SCAN_URL_TESTNET,
  SCAN_URL_DEVENT,
  SCAN_URL_POS_DEV,
  RemainderAmount,
} from "../constants";

export const isTestNetEnv = () => {
  if (typeof window !== `undefined`) {
    return (
      process.env.REACT_APP_TestNet === "true" ||
      window.location.hostname.includes("test")
    );
  }
  return false;
};

export const getCfxByVote = (vote) =>
  new BigNumber(vote).multipliedBy(CFX_BASE_PER_VOTE).toNumber();
export const getApy = (data) => new BigNumber(data).dividedBy(100).toNumber();
export const getFee = (userFee) =>
  new BigNumber(100).minus(new BigNumber(userFee).dividedBy(100)).toNumber();

// add 10% by default
export function calculateGasMargin(value, margin = 0.1) {
  return new BigNumber(value?.toString(10))
    .multipliedBy(new BigNumber(10000).plus(new BigNumber(10000 * margin)))
    .dividedBy(new BigNumber(10000))
    .toFixed(0);
}

export const getDateByBlockInterval = (minuend = 0, subtrahend = 0, space = 'core') => {
  const minuendBn = new BigNumber(minuend);
  const subtrahendBn = new BigNumber(subtrahend);
  const isGreater = minuendBn.isGreaterThan(subtrahendBn);
  const seconds = isGreater
    ? minuendBn.minus(subtrahendBn).dividedBy(space === 'core' ? 2 : 1).toNumber()
    : subtrahendBn.minus(minuendBn).dividedBy(space === 'core' ? 2 : 1).toNumber();
  const futureDate = new Date(
    new BigNumber(Date.now()).plus(seconds * 1000).toNumber()
  );
  const pastDate = new Date(
    new BigNumber(Date.now()).minus(seconds * 1000).toNumber()
  );
  return isGreater ? futureDate : pastDate;
};

export const getEllipsStr = (str, frontNum, endNum) => {
  if (str) {
    const length = str.length;
    if (endNum === 0 && length <= frontNum) {
      return str.substring(0, frontNum);
    }
    return (
      str.substring(0, frontNum) +
      "..." +
      str.substring(length - endNum, length)
    );
  }
  return "";
};

export const getNetworkUrl = async () => {
  let networkVersion = "";
  if (typeof window !== `undefined`) {
    networkVersion = await window.conflux?.request({method: "net_version"});
  }

  let url = "";
  switch (networkVersion) {
    case "1029":
      url = SCAN_URL;
      break;
    case "1":
      url = SCAN_URL_TESTNET;
      break;
    case "1921":
      url = SCAN_URL_DEVENT;
      break;
    case "8888":
      url = SCAN_URL_POS_DEV;
      break;
    default:
      url = SCAN_URL;
      break;
  }
  return url;
};

export const getMax = (amount) => {
  const amountBn = new BigNumber(amount);
  if (
    amountBn.isGreaterThanOrEqualTo(
      new BigNumber(CFX_BASE_PER_VOTE).plus(RemainderAmount)
    )
  ) {
    const multiple = Math.floor(
      new BigNumber(amount)
        .minus(RemainderAmount)
        .dividedBy(CFX_BASE_PER_VOTE)
        .toNumber()
    );
    return new BigNumber(multiple).multipliedBy(CFX_BASE_PER_VOTE).toNumber();
  }
  return 0;
};

export const getPrecisionAmount=(amount,precision=0)=>{
  return new BigNumber(amount).toFixed(precision)
}

export const cutString = (str, len1, len2) => {
  if (!str) return

  let strlen = str.length
  if ( strlen <= len1 + len2) return str

  str = str.substring(0, len1) + '...' + str.substring(strlen - len2)

  return str
}

export function getRewardTime(time) {
  const date = new Date(time) // change it to date object
  const month = (date.getMonth() + 1).toString().padStart(2, '0') // get month
  const day = date.getDate().toString().padStart(2, '0') // get day

  return `${month}-${day}`
}

export function getFormatTime(time) {
  if (!/^\d+$/.test(time)) {
    return false
  }

  const date = new Date(time * 1000)
  const year = date.getFullYear()
  const month = ("0" + (date.getMonth() + 1)).slice(-2)
  const day = ("0" + date.getDate()).slice(-2)

  const hours = ("0" + date.getHours()).slice(-2)
  const minutes = ("0" + date.getMinutes()).slice(-2)
  const seconds = ("0" + date.getSeconds()).slice(-2)

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

export const getOperationType = (type) => {
  switch (type) {
    case 1:
      return 'Stake'
    case 2:
      return 'Unstake'
    case 3: 
      return 'Withdraw'
    case 4: 
      return 'Claim'
    default:
      return 'Stake'
  }
}

export const exportCsv = (data, fileName) => {
  const url = window.URL.createObjectURL(new Blob(['\ufeff' + data], { type: 'text/csv;charset=UTF-8' }))
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  link.setAttribute('download', `${fileName}.csv`)
  link.setAttribute('title', fileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  window.URL.revokeObjectURL(url)
}

export const formatNumber = (number, decimalPlaces) => {
  const num = Number(number)
  if (num === 0) {
    return '0'
  }
  let numStr = num.toString()
  if (numStr.includes('e')) {
    numStr = num.toFixed(20).replace(/\.?0+$/, '')
  }
  if (/^0\.0{8,}/.test(numStr)) {
    return '0'
  }
  if (num < 1 && /^0\.0{7,}/.test(numStr)) {
    return numStr.replace(/\.(\d*?)0+$/, '.$1').replace(/\.$/, '')
  }
  const factor = Math.pow(10, decimalPlaces)
  const truncated = Math.floor(num * factor) / factor
  return truncated.toString().replace(/\.(\d*?)0+$/, '.$1').replace(/\.$/, '')
}